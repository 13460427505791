import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {
  transform(index: number): string {
    var weekDays = {
      0: 'Segunda',
      1: 'Terça',
      2: 'Quarta',
      3: 'Quinta',
      4: 'Sexta',
      5: 'Sábado',
      6: 'Domingo'
    };

    return index === 0 
      ? 'Amanhã' 
      : weekDays[moment().add(index, 'd').weekday()] || '';
  }
}
